/*@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');*/
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&subset=korean');

body {
    margin: 0;
    padding: 0;
    background-color: #F7F7F7;
    word-break: keep-all;
}

/*Font*/
* {
    /*font-family: 'NanumSquareRound',sans-serif !important;*/
    font-family: 'Noto Sans KR', sans-serif !important;
}

/*h1, h2, h3, h4 {
    font-weight: 400 !important;
}

div.title, .header {
    font-weight: 400 !important;

}*/

.headerText {
    font-weight: 900;
    font-size: 1.4rem;
}

table, .accordion, .item.active, .ui.card, .ui.card > img.ui.image, .ui.button {
    border-radius: 0px !important;
}

.accordion > .active.title, .accordion > .active.content {
    background-color: white;
}

.accordion > .active.content {
    padding: 1em !important;
}

.rbc-calendar {
    font-size: 0.5em !important;
}

.rbc-toolbar > .button {
    border-radius: 0px !important;
}

.rbc-btn-group > button {
    border-radius: 0px !important;
    font-size: 0.8em;
}

i.icon {
    font-family: Icons !important;
}

@media only screen and (max-width: 700px) {
    h2 {
        font-weight: 400 !important;
        font-size: 1.3em !important;
    }

}

@media only screen and (max-width: 767px) {
    .ui.container.noMargin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ui.container > .ui.stackable.grid > .column, .ui.container > .ui.stackable.grid > .row > .column.headerGrid {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .ui.card > :first-child, .ui.cards > .card > :first-child {
        border-radius: 0 !important;
    }
}
